import Vue from 'vue'
import Vuex from 'vuex'

import MobileDetect from 'mobile-detect';

import Data from '@/utils/data.js';
import { ossParams, serviceConfigList } from '@/apis/rescue';
import { getCustomerInfo, dispatchDriverInfo, pictureTemplate } from '@/apis/checkList'
import { rescueImg, mancar, destination,
	roidsign, emptyaddress, owner_sign, meet_sign, driver_sign, eroadwork, other, } from "@/utils/uploadImg.js";                //上传图片规则
import { getMobileModel } from '@/utils/index';

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		// address: "",
		// city: "",
		// district: "",
		// lat: 0,
		// lng: 0,
		// province: "",
		// type: "1",
		configData: Data.configData,		//水印配置数据
		location_s: null,
		location_e: null,
		driverInfo: '',			//聚合同步技师信息
		typeDataConfig: [],		//项目配置
		mobileDevice: ''
	},
	mutations: {
		SET_LOCATION(state, location){
			if (location.type == 1){
				state.location_s = location;
			}else{
				state.location_e = location;
			}
		},
		CLEARLOCATION(state, type){
			if (type == 1){
				state.location_s = null;
			}else{
				state.location_e = null;
			}
		},
		SETWATERDATA(state, waterData){
			state.configData = waterData;
		},
		SETDRIVERINFO(state, e){
			state.driverInfo = e;
		},
		SET_TYPE_DATA(state, e){
			state.typeDataConfig = e;
			Vue.prototype.typeDataConfig = e;
		},
		SET_DEVICE(state, e){
			state.mobileDevice = e;
		}
	},
	actions: {
		getOSSInfo(){
			return new Promise((resolve, reject) => {
				ossParams().then((res) => {
					localStorage.setItem('ossInfo', JSON.stringify(res.data.data));
					resolve(res.data.data);
				})
			})
		},
        // 水印配置数据
		getWaterData({ commit }, customer_id){
			return new Promise((resolve, reject) => {
				getCustomerInfo({ customer_id }).then((res)=>{
					let info = res.data.data;
					let waterData = Data.configData;
					let waterStatus = info.water_status;
					let waterClosely = info.water_closely;
					let sortUpload = info.sort_upload;
					if(info.water_data){
						waterData = JSON.parse(info.water_data);
					}
					waterData.waterStatus = waterStatus;
					waterData.waterClosely = waterClosely;
					waterData.sortUpload = sortUpload;
					commit('SETWATERDATA', waterData)
					resolve(waterData)
				})
			})
        },
        // 聚合同步技师信息
		getDispatchDriverInfo({ commit }, id){
			return new Promise((resolve, reject) => {
				dispatchDriverInfo({ id }).then((res)=>{
					let driverInfo = res.data.data;
					commit('SETDRIVERINFO', driverInfo)
					resolve(driverInfo)
				}).catch((err)=>{
					commit('SETDRIVERINFO', '')
					reject('')
				})
			})
        },
		// 照片模板配置
		getPhotoKeyTemplate({ commit, state }, type){
		    return new Promise((resolve, reject) => {
				pictureTemplate({page: 1,limit: 10000}).then((res)=>{
					let typePictureData = [];
					let typeData = state.typeDataConfig;
					let rescueImgData = [];
					for(let t=0; t<typeData.length; t++){
						if(type == typeData[t].no){
							let rescueImgArr = null;
							for(let r=0; r<rescueImg.length; r++){
								if(type == rescueImg[r].type){
									rescueImgArr = rescueImg[r].data;
									break
								}
							}
							let obj = {
								type,
								data: rescueImgArr ? rescueImgArr : typeData[t].type == 2 ? [mancar, destination,
									roidsign, emptyaddress, owner_sign, meet_sign, driver_sign, eroadwork, other,] : 
									[ mancar, roidsign, emptyaddress, owner_sign, driver_sign, eroadwork, other, ]
							}
							rescueImgData.push(obj)
							break
						}
					}
					let list = res.data.data;
					for(let d=0; d<rescueImgData.length; d++){
						if(type == rescueImgData[d].type){
							typePictureData = rescueImgData[d].data;
							for(let j=0; j<typePictureData.length; j++){
								for(let k=0; k<list.length; k++){
									if(typePictureData[j] && typePictureData[j].key == list[k].key){
										typePictureData[j].name = list[k].name;
										typePictureData[j].picture = list[k].picture;
										if(list[k].status == 0){
											typePictureData.splice(j, 1)
										}
										break
									}
								}
							}
						}
					}
					resolve(typePictureData)
				})
		    })
		},
		getTypeDataConfig({ commit }){
			serviceConfigList().then((res)=>{
				let data = res.data.data;
				console.log(data, '项目配置')
				commit('SET_TYPE_DATA', data)
			})
		},
		// 获取设备型号
		getDeviceInfo({ commit }, userAgent){
            const md = new MobileDetect(userAgent);
            const os = md.os(); //获取系统
            const model = getMobileModel(md, os, userAgent); 	//设备型号
			commit('SET_DEVICE', model)
        },
	},
	modules: {
	}
})
