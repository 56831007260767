<template>
  <div id="app">
    <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
export default {
  mounted(){
    this.$store.dispatch('getTypeDataConfig')
  }
}
</script>

<style lang="scss">
  :root{
    --theme-color: #FFD500;     //主题色
    --theme-text-color: #FCC717;     //主题色
    --theme-btn: #000;     //主题文字色
    --theme-text-color: #F7AB00;     //主要文字色
    --opcity-bg: rgba(246, 127, 9, 0.1);
  }
  #app{
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
</style>
