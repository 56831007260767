<template>
	<div class="view" ref="list">
		<div class="hearder">
            <div class="firmName">
                <van-icon name="wap-home" size="20" :color="$themeColor" />
                <!-- <img src="@/assets/rescueImgs/firmIcon.png" alt="" class="firmIcon" /> -->
                <span class="customerName">{{ customer_name }}</span>
            </div>
            <div class="openOrder" @click="pageBack">下单</div>
        </div>
		<div class="content">
			<div class="searchBox">
				<div class="search" v-if="isToken">
					<div class="date">
						<div class="dateBox">
							<span @click="timePicker = true" :style="form.date ? '' : 'min-width: 84px;'">{{ form.date ? form.date : '全部' }}</span>
							<van-icon name="clear" size="14" color="#C7C7C7" class="clearIcon" @click="clearDate" v-if='form.date'/>
						</div>
						<van-icon name="arrow-down" size="12" color="#C7C7C7" @click="timePicker = true"/>
					</div>
					<div class="input">
						<img src="@/assets/rescueImgs/search.png" alt="">
						<van-field type="text" v-model="form.keyword" placeholder='手机号/车牌号' clearable/>
					</div>
				</div>
				<div class="search" v-else>
					<div class="input">
						<img src="@/assets/rescueImgs/search.png" alt="">
						<van-field type="text" v-model="form.keyword" placeholder='手机号/车牌号' clearable/>
					</div>
					<div class="allBtn" @click="openAllOrder">全部</div>
				</div>
			</div>
			<div class="list">
				<div class="info" v-if="isToken || orderList.length !== 0">
					<span>累计：{{ countArr[0] }}</span>
					<span>完成：{{ countArr[1] }}</span>
					<span>空驶：{{ countArr[2] }}</span>
					<span>取消：{{ countArr[3] }}</span>
				</div>
				<van-empty description="输入下单手机号查询订单" v-if="orderList.length == 0 && !isToken"/>
				<van-empty description="暂无订单" v-if="orderList.length == 0 && isToken"/>
				<van-pull-refresh
				 	v-else
					v-model="isLoading"
					success-text="刷新成功"
					@refresh="refresh">
					<div class="li" v-for="(item, index) in orderList" :key="index" @click="toDetail(item.id)">
						<div class="hearderTitle">
							<span class="no">订单号 {{ item.no }}</span>
							<span class="status" v-if='item.is_pay == 1 && item.money != 0 && $statusRule(1, item)' style="color: #FF451E;">未支付</span>
							<span v-else class="status" :style="item.status == 1 ? 'color: #FF451E;' : ''">{{ item.status | filterStatus }}</span>
						</div>
						<div class="line"></div>
						<div class="li_info">
							<span class="label">订单类型：</span>
							<span class="value">{{ item.type | filterType }}</span>
						</div>
						<div class="li_info">
							<span class="label">车辆信息：</span>
							<span class="value">{{ item.car_no }}</span>
						</div>
						<div class="li_info">
							<span class="label">救援地：</span>
							<span class="value">{{ item.address }}</span>
						</div>
						<div class="li_info" v-if="$jyTypeData(item.type, 'address') == 2">
							<span class="label">目的地：</span>
							<span class="value">{{ item.destination }}</span>
						</div>
						<div class="time">{{ item.create_time | filterTime }}</div>
					</div>
				</van-pull-refresh>
			</div>
			<div class="loadMore" v-if="orderList.length !== 0 && isToken">{{ loadMoreText }}</div>
		</div>
		<van-popup v-model="timePicker" round position="bottom" safe-area-inset-bottom>
			<van-datetime-picker
				v-model="currentDate"
				type="year-month"
				title="选择年月"
				:min-date="minDate"
				:max-date="maxDate"
				:formatter="formatter"
				@confirm='confirmDate'
				@cancel='timePicker = false'/>
		</van-popup>
        <!-- 输入密码 -->
        <van-dialog 
            v-model="passwordDialog" 
            show-cancel-button
            title='查单密码'
            :confirm-button-color='$themeColor'
            cancel-button-color='#999999'
            :beforeClose='configPwd'>
            <div class="passwordInput">
                <van-field
                    v-model="password"
                    type="password"
                    placeholder="请输入查单密码"/>
            </div>
        </van-dialog>
		<CustomerService />
	</div>
</template>

<script>
import util from '@/utils';
import Data from '@/utils/data.js';
import { orderList, login, noTokenOrderList } from '@/apis/rescue';
import {debounce} from '@/utils/debounce';
import md5 from 'js-md5';
import CustomerService from '@/components/rescue/customerService';
export default {
	data(){
		return {
			form: {
				keyword: '',
				date: ''
			},
			customer_name: '',
			firm_name: '',
			orderList: [],
			countArr: [],
			isLoading: false,
			startY: 0,
			startX: 0,
			moreLoading: false,
            loadMoreText: '上拉加载更多',
			morePage: 2,
			timePicker: false,
			minDate: new Date(2018, 0, 1),
			maxDate: new Date(),
			currentDate: new Date(),
			isToken: false,
            passwordDialog: false,
			password: ''
		}
	},
    components:{
        CustomerService
    },
	created(){
		let query = this.$route.query;
		this.customer_name = query.name;
		this.firm_name = query.firm_name;
		this.firm_id = query.firm_id;
		this.customer_id = query.customer_id;
		document.title = this.firm_name;
		if(query.mobile){
			this.form.keyword = query.mobile;
			this.getNoTokenList();
		}
		let nowDate = new Date();
		this.getTime(nowDate);
		this.$watch('form.keyword', debounce((newQuery) => {
			// newQuery为输入的值
			console.log(newQuery);
			if(this.isToken){
				this.getList();
			}else{
				if(this.form.keyword && this.form.keyword.length > 5){
					this.getNoTokenList();
				}else{
					this.countArr = [];
					this.orderList = [];
				}
			}
		}, 1000))
	},
	mounted(){
		this.$nextTick(()=>{
			window.addEventListener("scroll",this.screenCheck, true);
		})
	},
	beforeDestroy(){
		window.removeEventListener("scroll",this.screenCheck, true)
	},
	methods: {
		getTime(date){
			let nowYear = date.getFullYear();
			let nowMonth = date.getMonth();
			let m = nowMonth + 1;
			if (m.toString().length == 1) {
				m = "0" + m;
			}
			console.log(nowYear +'-'+ m,'选择时间');
			this.form.date = nowYear +'-'+ m;
		},
		formatter(type, val) {
			if (type === 'year') {
				return `${val}年`;
			} else if (type === 'month') {
				return `${val}月`;
			}
			return val;
		},
		// 选择时间
		confirmDate(e){
			this.getTime(e);
			this.timePicker = false;
		},
		// 清空时间
		clearDate(){
			this.form.date = '';
			this.getList();
		},
		// 下拉刷新
		refresh(){
			if(this.isToken){
				this.getList();
			}else{
				this.getNoTokenList();
			}
		},
		getNoTokenList(){
			let data = {
				page: 1,
				limit: 100,
				id: this.customer_id,
				keyword: this.form.keyword
			}
			noTokenOrderList(data).then((res)=>{
				console.log(res)
				this.isLoading = false;
				this.countArr = res.data.data.count;
				this.orderList = res.data.data.data;
			})
		},
		getList(page){
			let data = {
				page: page ? page : 1,
				limit: 10,
				keyword: this.form.keyword,
				date: this.form.date
			}
			orderList(data).then((res)=>{
				console.log(res,'订单列表')
				if(res.data.code == 50008){
					this.$toast('登录超时！');
					sessionStorage.removeItem('token');
					this.isToken = false;
					this.passwordDialog = true;
					return
				}
				this.countArr = res.data.data.count;
				let list = res.data.data.data;
				if(page){
					if(list.length == 0){
						this.loadMoreText = '没有更多了';
					}else{
						this.morePage++;
						this.orderList = this.orderList.concat(list);
						this.loadMoreText = '上拉加载更多';
					}
					this.moreLoading = false;
				}else{
					this.orderList = list;
					this.morePage = 2;
					this.isLoading = false;
				}
			}).catch(()=>{
				this.moreLoading = false;
			})
		},
		toDetail(id){
			this.$router.push({
                path: '/orderDetail',
                query: {
                    id
                }
            })
		},
		screenCheck(){
			let scrollH = window.scrollY + window.innerHeight;
			let offsetHeight = this.$refs.list.offsetHeight;
			if(scrollH >= offsetHeight){
				console.log(scrollH,offsetHeight,'页面触底');
				if(!this.moreLoading && this.isToken){
					this.loadMoreList();
				}
			}
		},
		loadMoreList(){
			if(this.orderList.length > 9){
				this.moreLoading = true;
				this.loadMoreText = '努力加载中...';
				this.getList(this.morePage)
			}else{
				this.loadMoreText = '没有更多了';
			}
		},
		// 下单
		pageBack(){
			this.$router.go(-1);
		},
        // 确认密码
        async configPwd(action, done){
            let that = this;
             if (action === 'confirm') {
                if(!this.password){
                    this.$toast('请填写密码！');
                    done(false)
                }else{
                    let res = await this.login();
                    if(res){
                        done();
						this.isToken = true;
						if(this.form.keyword){
							this.form.keyword = '';
						}else{
							this.getList();
						}
                    }else{
                        done(false);
                    }
                }
            }else{
                done()
            }
        },
        async login(){
            let data = {
                firm_id: this.firm_id,
                id: this.customer_id,
                password: md5(this.password)
            }
            let res = await login(data);
            console.log(res,'登陆')
            if(res.data.code !== 0){
                let token = res.data.data.token;
                sessionStorage.setItem('token', token);
                return true
            }else{
                this.$toast(res.data.info);
                return false
            }
        },
		openAllOrder(){
			let token = sessionStorage.getItem('token');
			if(token){
				this.isToken = true;
				if(this.form.keyword){
					this.form.keyword = '';
				}else{
					this.getList();
				}
			}else{
				this.passwordDialog = true;
			}
		}
	},
	filters: {
		filterTime(val){
			if(val){
				return util.formatTime(val)
			}
		},
		filterStatus(val){
			if(val || val == 0){
				let orderStatus = Data.orderStatus;
				return orderStatus[val]
			}else{
				return '-'
			}
		}
	},
}
</script>

<style lang="scss" scoped>
	.view{
		background: #F9F9F9;
		min-height: 100vh;
	}
	.hearder{
		z-index: 99;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		height: 47px;
		background: rgba(255, 255, 255, 0.83);
		display: flex;
		padding: 0 15px;
		box-sizing: border-box;
		align-items: center;
		justify-content: space-between;
		.firmName{
			display: flex;
			align-items: center;
			font-size: 16px;
			line-height: 22px;
			color: #1F2129;
			.firmIcon{
				width: 16px;
				height: 15px;
			}
			.customerName{
				margin-left: 5px;
			}
		}
		.openOrder{
			width: 64px;
			height: 28px;
			background: #FFFFFF;
			border: 1px solid var(--theme-color);
			opacity: 1;
			border-radius: 4px;
			font-size: 14px;
			color: var(--theme-text-color);
			text-align: center;
			line-height: 28px;
		}
	}
	.content{
		padding-top: 47px;
		box-sizing: border-box;
		border-top: 1px solid #ECECEC;
		.searchBox{
			height: 60px;
			background: #FFFFFF;
			display: flex;
			justify-content: center;
			align-items: center;
			.search{
				width: 345px;
				height: 38px;
				background: #EEEEEE;
				display: flex;
				justify-content: center;
				align-items: center;
				.date{
					width: 109px;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					color: #141414;
					border-right: 1px solid #E2E1E1;
					font-size: 14px;
					.dateBox{
						min-width: 84px;
						display: flex;
						align-items: center;
						span{
							display: inline-block;
							text-align: center;
						}
					}
					.clearIcon{
						margin: 0 7px;
					}
				}
				.input{
					flex: 1;
					padding: 0 8px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					font-size: 14px;
					.van-cell{
						background: #EEEEEE;
						height: 38px;
						padding: 0;
						line-height: 38px;
					}
					img{
						width: 17px;
						height: 17px;
						margin-right: 5px;
					}
				}
				.allBtn{
					width: 50px;
					height: 100%;
					text-align: center;
					font-size: 14px;
					color: var(--theme-text-color);
					line-height: 38px;
				}
			}
		}
		.list{
			padding: 15px;
			box-sizing: border-box;
			font-size: 14px;
			.info{
				display: flex;
				align-items: center;
				justify-content: space-between;
				font-family: PingFang SC;
				font-weight: 500;
				line-height: 20px;
				color: #141414;
			}
			.li{
				margin-top: 11px;
				background: #FFFFFF;
				box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
				opacity: 1;
				border-radius: 4px;
				padding: 0 10px 10px 15px;
				box-sizing: border-box;
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 400;
				.hearderTitle{
					height: 50px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					.no{
						color: #999999;
					}
					.status{
						font-weight: bold;
						color: var(--theme-text-color);
					}
				}
				.line{
					border-bottom: 1px dashed var(--theme-color);
					margin-bottom: 15px;
				}
				.li_info{
					margin-bottom: 15px;
					line-height: 20px;
					.label{
						color: #7F7F7F;
						margin-right: 5px;
					}
					.value{
						color: #191818;
					}
				}
				.time{
					text-align: right;
					line-height: 17px;
					color: #666666;
				}
			}
		}
		.loadMore{
			text-align: center;
			color: #999999;
			font-size: 14px;
			padding-bottom: 10px; 
		}
	}
	.passwordInput{
		width: 271px;
		border: 1px solid #EFEFEF;
		opacity: 1;
		border-radius: 4px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		margin: 22px auto 24px;
	}
</style>
