<template>
	<div class="view">
        <div class="amap" id='container'></div>
        <img v-if="isWXPay" src="@/assets/rescueImgs/wx_yd.png" alt="" class="wx_yd" />
        <div class="hearder">
            <div class="firmName">
                <van-icon name="wap-home" size="20" :color="$themeColor" />
                <!-- <img src="@/assets/rescueImgs/firmIcon.png" alt="" class="firmIcon" /> -->
                <span class="customerName">{{ customer_name }}</span>
            </div>
            <div class="openOrder" @click="seeOrder">查单</div>
        </div>
        <div class="content">
            <div class="stepOne" v-if='step == 1'>
                <div class="distinguish_btn" @click="distinguish_show = true">智能识别下单</div>
                <div class="info">
                    <div class="tabbar">
                        <div v-if='jyTypeData.length !== 0'>
                            <template v-for="(item, index) in jyTypeData">
                                <div :class="['tabbar_li', form.type == item.type ? 'activeTabbar' : '']" @click="handType(item)" :key="index">
                                    {{ item.type | filterType('short') }}
                                </div>
                            </template>
                        </div>
                        <div v-else>
                            <div class="noServe" v-if="!lat || !lng">
                                <img src="@/assets/rescueImgs/no_serve.png" alt="" />
                                <span>定位失败，请手动选择地址</span>
                            </div>
                            <div class="noServe" v-else>
                                <img src="@/assets/rescueImgs/no_serve.png" alt="" />
                                <span>该区域暂未开放服务，请联系开通！</span>
                            </div>
                        </div>
                    </div>
                    <div class="info_li address" @click="openLocation(1)">
                        <img src="@/assets/rescueImgs/e_icon.png" alt=""/>
                        <span class="label">起点</span>
                        <span class="name">{{ address ? address : '请选择' }}</span>
                    </div>
                    <div class="info_li userInfo" v-if='jyTypeData.length !== 0'>
                        <div class="username">
                            <img src="@/assets/rescueImgs/user_icon.png" alt=""/>
                            <van-field type="text" v-model="form.contact" :placeholder=" form.type == 1 || form.type == 11 ? '请输入装车人' : '请输入联系人' " />
                        </div>
                        <div class="username">
                            <img src="@/assets/rescueImgs/phone_icon.png" alt=""/>
                            <van-field type="number" v-model="form.phone" placeholder="请输入手机号" maxlength='11'/>
                        </div>
                    </div>
                    <div class="info_li address" v-if='$jyTypeData(form.type, "address") == 2' @click="openLocation(2)">
                        <img src="@/assets/rescueImgs/e_icon.png" alt=""/>
                        <span class="label">终点</span>
                        <span class="name">{{ e_address ? e_address : '请选择' }}</span>
                    </div>
                    <div class="info_li userInfo" v-if='$jyTypeData(form.type, "address") == 2'>
                        <div class="username">
                            <img src="@/assets/rescueImgs/user_icon.png" alt=""/>
                            <van-field type="text" v-model="form.unload_contact" placeholder="请输入接车人" />
                        </div>
                        <div class="username">
                            <img src="@/assets/rescueImgs/phone_icon.png" alt=""/>
                            <van-field type="number" v-model="form.unload_mobile" placeholder="请输入手机号" maxlength='11' />
                        </div>
                    </div>
                </div>
                <div class="footerBtn" @click="nextStep">下一步</div>
            </div>
            <div v-if='step == 2' class="stepTwo">
                <div class="form">
                    <div class="li">
                        <div class="label">
                            救援类型
                        </div>
                        <div class="value">
                            {{ form.type | filterType('short') }}
                        </div>
                    </div>
                    <div class="li">
                        <div class="label">
                            车牌号码
                        </div>
                        <div v-if="form.type == 11" class="value">
                            <van-field type="text" v-model="form.car_no" placeholder="请输入车牌号码或车架后六位" />
                        </div>
                        <div v-else class="value" :style=" form.car_no ? '' : 'color: #C7C7C7;' " @click="keyState = true">
                            {{ form.car_no ? form.car_no : '请输入车牌号码' }}
                        </div>
                    </div>
                    <div class="li" v-if="customer_id != default_customer_id">
                        <div class="label">
                            车架号码
                        </div>
                        <div class="value">
                            <van-field type="text" v-model="form.vin" placeholder="请输入车架号码" />
                        </div>
                    </div>
                    <div class="li">
                        <div class="label">
                            品牌型号
                        </div>
                        <div class="value">
                            <van-field type="text" v-model="form.car_model" placeholder="请输入品牌型号" />
                        </div>
                    </div>
                    <div class="li" @click="cartypePicker = true">
                        <div class="label">
                            汽车类型
                        </div>
                        <div class="value">
                            {{ form.car_type | filterCartype('title') }}
                        </div>
                    </div>
                    <div class="li" v-if='form.type == 10' @click="craneTonnagePicker = true">
                        <div class="label">
                            吊车吨位
                        </div>
                        <div class="value">
                            {{ form.tonne_m }}吨
                        </div>
                    </div>
                    <div class="li" v-if="customer_id != default_customer_id">
                        <div class="label">
                            保单号码
                        </div>
                        <div class="value">
                            <van-field type="text" v-model="form.policy_no" placeholder="请输入保单号码" />
                        </div>
                    </div>
                    <div class="li" @click="showPicker = true">
                        <div class="label">
                            预约时间
                        </div>
                        <div class="value">
                            <div class="right_icon">
                                <span>{{ form.appoint_time }}</span>
                                <van-icon name="arrow" size="14" color="#B1B1B1"/>
                            </div>
                        </div>
                    </div>
                    <div class="li" @click="openPhotoPopup">
                        <div class="label">
                            现场照片
                        </div>
                        <div class="value">
                            <div class="right_icon">
                                <span>({{ form.photo.length }})</span>
                                <van-icon name="arrow" size="14" color="#B1B1B1"/>
                            </div>
                        </div>
                    </div>
                    <div class="li" v-if='form.type !== 11'>
                        <div class="label">
                            是否事故
                        </div>
                        <div class="value">
                            <van-checkbox :disabled='customer_id == default_customer_id' v-model="form.is_incident_car" shape="square" icon-size="14px">是</van-checkbox>
                            <van-field 
                                v-if='form.is_incident_car' 
                                type="text" 
                                v-model="form.accident_no" 
                                :placeholder=" customer_id == default_customer_id ? '请输入报案号' : '请输入事故编号'" 
                                class="accidentInput"/>
                        </div>
                    </div>
                    <div class="li" v-if='form.is_incident_car' @click='customer_id == default_customer_id ? toSurveyor() : ""'>
                        <div class="accident_user">
                            <van-field
                                :disabled='customer_id == default_customer_id'
                                v-model="form.checker_name"
                                left-icon="contact"
                                placeholder="查勘员姓名"/>
                        </div>
                        <div class="accident_user">
                            <van-field
                                :disabled='customer_id == default_customer_id'
                                v-model="form.checker_phone"
                                left-icon="phone-o"
                                placeholder="查勘员电话"
                                type="number"
                                maxlength='11'/>
                        </div>
                    </div>
                    <div class="li" v-if='form.type == 1 || form.type == 11'>
                        <div class="label">
                            额外需求
                        </div>
                        <div class="value">
                            <van-checkbox-group v-model="demand" class="demandView" @change="computedMoney">
                                <van-checkbox name="science" v-if='form.type == 1' shape="square" icon-size="14px">车在地库</van-checkbox>
                                <van-checkbox name="one_two" v-if='form.type == 11' shape="square" icon-size="14px">一拖二</van-checkbox>
                                <van-checkbox name="big_vehicle" v-if='form.type == 11' shape="square" icon-size="14px">五吨板</van-checkbox>
                                <van-checkbox name="full" v-if='form.type == 11' shape="square" icon-size="14px">全落地</van-checkbox>
                                <van-checkbox name="use_wheels" v-if='form.type == 1' shape="square" icon-size="14px">辅轮</van-checkbox>
                                <div></div>
                            </van-checkbox-group>
                        </div>
                    </div>
                    <div class="li" v-if='canPay'>
                        <div class="label moneyLabel" @click="moneyInfoShow = true">
                            <span>预计费用</span>
                            <van-icon name="question-o" color="#8A8A8A" size="14px"/>
                        </div>
                        <div class="value money">
                            ￥{{ moneyInfo.total_money }}
                        </div>
                    </div>
                    <div class="li">
                        <div class="label">
                            订单备注
                        </div>
                        <div class="value">
                            <van-field type="text" v-model="form.remark" placeholder="请输入订单备注" />
                        </div>
                    </div>
                </div>
                <div class="footerBtn">
                    <div class="preStep" @click="proStep">上一步</div>
                    <div class="submit" @click="validSubmit">立即下单</div>
                </div>
            </div>
        </div>
        <keyword v-if='keyState' :isShow="keyState" @exit="exit" :oinp="form.car_no" @ok="confirm"/>
        <!-- 现在时间 -->
        <van-popup v-model="showPicker" round position="bottom" safe-area-inset-bottom>
            <van-picker 
                show-toolbar 
                title="预约时间" 
                :columns="timePicker"
                @confirm='confirmTime'
                @cancel='showPicker = false'/>
        </van-popup>
        <!-- 汽车类型 -->
        <van-popup v-model="cartypePicker" round position="bottom" safe-area-inset-bottom>
            <van-picker 
                show-toolbar 
                title="汽车类型" 
                :columns="cartypeData"
                @confirm='confirmCartype'
                @cancel='cartypePicker = false'/>
        </van-popup>
        <!-- 吊车吨位 -->
        <van-popup v-model="craneTonnagePicker" round position="bottom" safe-area-inset-bottom>
            <van-picker 
                show-toolbar 
                title="吊车吨位" 
                :columns="craneTonnageData"
                @confirm='confirmCraneTonnage'
                @cancel='craneTonnagePicker = false'/>
        </van-popup>
        
        <!-- 现场照片 -->
        <van-popup v-model="photoPopup" round position="bottom" safe-area-inset-bottom closeable>
            <div class="photoPopup">
                <div class="title">现场照片</div>
                <div class="photoInfo">
                    <div class="tips">
                        <img src="@/assets/rescueImgs/tips_icon.png" alt=""/>
                        <p>提示：照片内容最好展示包括车牌、车型、现场状况等内容，方便师傅为您提供对应的救援服务</p>
                    </div>
                    <div class="list">
                        <div class="imgBox">
                            <div class="li" v-for="(item, index) in uploadImgs" :key="index" :style="(index + 1) % 3 == 0 ? 'margin-right: 0;' : ''">
                                <img :src="item" alt="" @click="preImg(index)"/>
                                <van-icon name='clear' size="16" color="#FB421A" class="clearImg" @click="clearImg(index)"></van-icon>
                            </div>
                            <van-uploader class="uploadBtn" :before-read='beforeRead' :after-read="afterRead" accept='image/*' result-type='file'/>
                        </div>
                    </div>
                    <div class="footerBtn">
                        <div class="cancelBtn" @click="cancelUpload">取消</div>
                        <div class="affirmBtn" @click="affirmUpload">确定</div>
                    </div>
                </div>
            </div>
        </van-popup>
        <!-- 下单成功 -->
        <van-dialog 
            v-model="orderSuccess" 
            show-cancel-button
            confirm-button-text='继续下单'
            :confirm-button-color='$themeColor'
            cancel-button-text='查看订单'
            cancel-button-color='#999999'
            @confirm='continueCreate'
            @cancel='seeOrder(1)'>
            <div class="orderSuccess">
                <img src="@/assets/rescueImgs/success.png" alt=""/>
                <span>下单成功</span>
            </div>
        </van-dialog>
        <!-- 照片预览 -->
        <van-image-preview v-model="preImgShow" :images="uploadImgs" @change="preChange" :start-position='preIndex'>
            <template v-slot:index>第{{ preIndex + 1 }}页</template>
        </van-image-preview>
        <!-- 费用明细 -->
        <van-popup 
            v-model="moneyInfoShow" 
            round
            position="bottom">
            <div class="content">
                <div class="moneyInfo">
                    <div class="title">费用说明</div>
                    <div class="tips">
                        <template v-if='moneyInfo.mode == 1'>
                            救援服务费：{{ form.type | filterType('short') }}起步价￥{{ priceInfo.first_p }}（含{{ priceInfo.first_m }}公里），
                            超出按 {{ priceInfo.per_p }}元/公里（{{ priceInfo.end_p != 0 ? `${priceInfo.end_p}元封顶` : '不封顶' }}）。
                        </template>
                        <template v-else>
                            救援服务费：{{ form.type | filterType('short') }}￥{{ priceInfo.fixed_p }}起/次
                        </template>
                        <template v-if='form.type == 1'>
                            地库+{{ priceInfo.basement_p }}元，
                            使用辅轮+{{ priceInfo.use_wheels_p }}元/个（{{ priceInfo.use_wheels_end_p != 0 ? `${priceInfo.use_wheels_end_p}元封顶` : '不封顶' }}）。
                        </template>
                        <template v-if='form.type == 11'>
                            一拖二+{{ priceInfo.one_two_p }}元，
                            五吨板+{{ priceInfo.big_vehicle_p }}元，
                            全落地+{{ priceInfo.full_p }}元。
                        </template>
                    </div>
                    <div class="title">其他收费规则</div>
                    <div class="tips">
                        <div class="li">1.除救援费用外，救援过程中产生的其他费用，需要您承担，请在救援完成后现场支付给救援技师，包含过路、过桥、停车等费用；</div>
                        <div class="li">2.救援过程中救援目的地发生变更，需要重新计算救援费用。</div>
                    </div>
                    <div class="title">取消收费规则</div>
                    <div class="tips">
                        <div class="li">1.报案成功后，10分钟内可免费取消；</div>
                        <div class="li">2.超过10分钟后师傅到达现场前，将收取基础费用的50%作为服务师傅的空驶费；</div>
                        <div class="li">3.师傅到达现场后，不再支持取消订单。</div>
                        <div class="li">4.取消订单请联系客服：
                            <a href="tel:4000929555">4000929555</a>
                        </div>
                    </div>
                </div>
                <div class="btn" @click="moneyInfoShow = false">我知道了</div>
            </div>
        </van-popup>
        <!-- 人工客服 -->
        <!-- <CustomerService /> -->
        <!-- 智能识别 -->
        <van-overlay 
            :show="distinguish_show" 
            @click="distinguish_show = false"
            :z-index='999'
            class-name='overlay_view'>
            <div class="distinguish_box" @click.stop>
                <div class="distinguish_title">智能识别下单信息</div>
                <van-icon name="cross" size='18' class="cross_icon" @click="close_distinguish"/>
                <van-field
                    v-model="distinguish_text"
                    rows="4"
                    autosize
                    type="textarea"
                    @input="distinguish"
                    placeholder="将订单信息复制粘贴到此处识别"/>
                <div class="distinguish_info" v-if='distinguish_info'>
                    <div class="distinguish_li" v-for='(value, key, index) in distinguish_info' :key='index'>
                        <span v-if='value'>
                            {{ key | filterKey }}：{{ value }}
                        </span>
                    </div>
                </div>
                <div class="distinguish_affirm" v-if='distinguish_info' @click="affirm_distinguish">
                    确 认
                </div>
            </div>
        </van-overlay>
        <!-- 效验验证码 -->
        <van-dialog 
            :showConfirmButton='false'
            v-model="mobile_valid_dialog">
            <div class="validBox">
                <van-icon name="close" class="dialog_close_icon" size='22' color='#7F7F7F' @click="closeDialog"/>
                <div class="phoneInput_title">输入查勘员手机{{ form.checker_phone }}验证码</div>
                <div class="u-pop-message">
                    <div 
                        v-for="(item, index) in valid_length" 
                        :key='index' 
                        @click="inputFocus"
                        :class="['valid_li', validValue.length == index ? 'activeFocus' : '' ]">
                        <span>{{ validValue[index] }}</span>
                    </div>
                </div>
                <div class="phoneError">
                    <div class="error_text" v-if="!checkPhoneStatus">{{ checkPhoneText }}</div>
                </div>
            </div>
        </van-dialog>
        <div class="valid_input">
            <van-field v-model="validValue" ref='input' type="digit" :maxlength='valid_length' @input="changeInput"/>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import keyword from '@/components/rescue/keyword';
import { getPriceConfig, uploadFileOss, createOrder, ossParams, customerParameter, computePrice } from '@/apis/rescue';
import { getQueryString } from '@/utils/crypto';
import Data from '@/utils/data';
import { isWeiXin } from '@/utils/index';
import CustomerService from '@/components/rescue/customerService';
import { regularValue } from '@/utils/index';
import { sms_post } from '@/apis/unicloud/api';

export default {
    name: 'rescue',
    data() {
        let that = this;
        return {
            order_id: '',       //下单成功存储id
            code: '',           //微信code
            pay_no: '',         //h5支付订单号
            showPicker: false,
            step: 1,            //下单步骤
            customer_id: '',        //客户id
            default_customer_id: 141,       //事故下单需要查勘员的客户  45=>测试服测试     141==广州中华
            firm_id: '',              //服务商id
            firm_name: '',              //服务商id
            customer_name: '',
            password: '',
            keyState: false,
            zoom: 15,
            center: [121.59996, 31.197646],
            map: null,
            lng: 0,                 //定位点 起点
            lat: 0,                 //定位点 起点
            address: '',           //起点
            province: '',          //省
            city: '',               //市
            district: '',           //区
            e_lng: 0,                 //终点
            e_lat: 0,                 //终点
            e_address: '',             //终点
            e_province: '',          //省
            e_city: '',               //市
            e_district: '',           //区
            marker: [],
            marker_startIcon: require('@/assets/rescueImgs/marker_start.png'),
            marker_end: require('@/assets/rescueImgs/marker_end.png'),
            form: {
                type: '',               //救援类型
                contact: '',               //装车人
                phone: '',              //手机号
                car_no: '',             //车牌号码
                car_brand: '',          //车辆品牌
                car_model: '',          //品牌型号
                car_type: '1',            //汽车类型
                is_incident_car: false,        //是否事故
                photo: [],                  //现场图片
                science: '',                //救援环境 1 车在地库 2 车在路面（手刹空档）正常 3 车在路面（手刹空档）异常
                big_vehicle: 0,             //5吨板 0 否 1 是
                one_two: 0,                 //一拖二 0 否 1 是
                full: 0,                    //全落地 0 否 1 是
                use_wheels: 0,              //辅轮
                load_contact: '',           //装车联系人
                load_mobile: '',           //装车联系人电话
                unload_contact: '',           //卸车联系人
                unload_mobile: '',           //卸车联系人电话
                is_subscribe: '1',          //1 及时救援 2 预约救援
                appoint_time: '尽快到达',           //预约时间戳 is_subscribe 2 时使用
                customer_id: '',            //客户id
                remark: '',                  //订单备注
                a_province: '',             //救援地省
                a_city: '',             //救援地市
                a_area: '',             //救援地区
                address: '',             //详细地址
                theodolitic: '',            //救援地坐标
                d_province: '',             //目的地省
                d_city: '',             //目的地市
                d_area: '',             //目的地区
                destination: '',             //目的地详细地址
                transit: '',            //目的地坐标
                vin: '',            //车架号
                policy_no: '',           //保单号
                accident_no: '',           //事故编号
                checker_name: '',          //查勘员
                checker_phone: '',          //查勘员电话
                tonne_m: 8,        //吊车吨位
            },
            priceInfo: '',          //价格
            demand: [],             //额外需求
            defaultJyType: [
                {
                    status: false,
                    type: 1,
                    title: '拖车',
                },
                {
                    status: false,
                    type: 11,
                    title: '轿运',
                },
                {
                    status: false,
                    type: 2,
                    title: '搭电',
                },
                {
                    status: false,
                    type: 3,
                    title: '换胎',
                },
                {
                    status: false,
                    type: 4,
                    title: '困境',
                },
                {
                    status: false,
                    type: 7,
                    title: '快修',
                },
                {
                    status: false,
                    type: 8,
                    title: '充气',
                },
                {
                    status: false,
                    type: 12,
                    title: '送油',
                },
                {
                    status: false,
                    type: 6,
                    title: '送水',
                },
                {
                    status: false,
                    type: 10,
                    title: '吊车',
                },
                {
                    status: false,
                    type: 15,
                    title: '充电',
                }
            ],
            jyTypeData: [],
            timePicker: [],         //预约时间表
            intervalTimeArr: [],    //存间隔时间
            cartypePicker: false,   //汽车类型
            craneTonnagePicker: false,      //吊车吨位
            cartypeData: Data.carType,
            craneTonnageData: Data.craneTonnageData,
            photoPopup: false,        //现场照片
            uploadImgs: [],         //照片
            orderSuccess: false,         //下单成功
            // 照片预览
            picture: [],
            preImgShow: false,
            preIndex: 0,
            canPay: false,              //是否是现金下单
            isWXPay: false,
            moneyInfoShow: false,       //费用明细显隐
            moneyInfo: '',               //费用明细
            disabledSubmit: false,          //防止重复点击
            distinguish_show: false,         //识别窗口
            distinguish_text: '',           //可识别文本
            distinguish_info: '',
            mobile_valid_dialog: false,      //效验验证码
            valid_length: 4,
            validValue: '',
            checkPhoneStatus: true,
            checkPhoneText: ''
        }
    },
    components:{
        keyword,
        CustomerService
    },
    created(){
        this.customer_id = getQueryString('customer_id');
        this.firm_id = getQueryString('firm_id');
        this.form.customer_id = this.customer_id;
        if(this.customer_id == this.default_customer_id){
            // 中华事故默认事故
            this.form.is_incident_car = true;
        };
        // if(isWeiXin()){
        //     let state = getQueryString('state');
        //     if(state){
        //         this.code = getQueryString('code');
        //     }else{
        //         this.wxLogin();
        //     }
        // }
        this.getOSSParam();
        this.getCustomerInfo();
        console.log('created')
    },
    mounted() {
        let that = this;
        this.initMap();
        console.log('mounted')
        // history.pushState(null, null, document.URL);
        // window.addEventListener('popstate', function () {
        //     console.log('popstate', history);
        //     history.pushState(null, null, document.URL);
        // });
    },
    
    activated() {
        document.title = this.firm_name;
        let location_s = this.$store.state.location_s;
        let location_e = this.$store.state.location_e;
        console.log('activated', location_s, location_e)
        if(location_s && location_s.lng && location_s.lat){
            this.address = location_s.address;
            this.city = location_s.city;
            this.district = location_s.district;
            this.lat = location_s.lat;
            this.lng = location_s.lng;
            this.province = location_s.province;
            this.$store.commit('CLEARLOCATION', 1)
            this.getConfig();
            this.setMarker();
        }
        if(location_e && location_e.lng && location_e.lat){
            this.e_address = location_e.address;
            this.e_city = location_e.city;
            this.e_district = location_e.district;
            this.e_lat = location_e.lat;
            this.e_lng = location_e.lng;
            this.e_province = location_e.province;
            this.$store.commit('CLEARLOCATION', 2)
            this.setMarker();
        }
        if(this.customer_id == this.default_customer_id){
            // 中华保险查勘员
            let surveyorInfo = sessionStorage.getItem('surveyorInfo');
            if(surveyorInfo){
                surveyorInfo = JSON.parse(surveyorInfo);
                this.form.checker_name = surveyorInfo.name;
                this.form.checker_phone = surveyorInfo.phone;
                sessionStorage.removeItem('surveyorInfo');
            }
        }
    },
    filters: {
        // filterCartype(val){
        //     let data = {
        //         1: '轿车',
        //         2: '货车'
        //     }
        //     return data[val]
        // },
        filterKey(val){
            let data = {
                no: '报案号',                 //报案号
                accident: '事故号',                 //事故号
                vin: '车架号',                 //车架号
                carNo: '车牌号',                 //车牌
                car_model: '品牌型号',                 //车型
                name: '车主',                 //车主
                phone: '联系电话',                 //联系电话
            }
            return data[val]
        }
    },
    methods: {
        // 微信登陆
        wxLogin(){
            const location = window.location.href;
            // const location = window.location.origin + window.location.pathname;
            // const location = 'https://m.ycyh56.com/rescueCloudTest/#/rescue';
            // const location = 'http://192.168.0.133:8080/rescue'
            const appid = 'wx76c3962a7e7cb03b';
            console.log(window, location)
            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(location)}&response_type=code&scope=snsapi_base&state=1#wechat_redirect`;
        },
        // 客户信息
        getCustomerInfo(){
            let data = {
                id: this.customer_id,
                firm_id: this.firm_id
            }
            customerParameter(data).then((res)=>{
                console.log(data,'客户信息')
                this.canPay = res.data.data.can_pay == 1 ? true : false;
                
                this.firm_name = (process.env.VUE_APP_BASE_API == 'https://test.api.tc-rescue.com' || process.env.VUE_APP_BASE_API == 'https://v3.tc-rescue.com') && this.customer_id == 141 ? '慧星云救援' : res.data.data.firm_name;
                this.customer_name = res.data.data.name;
                document.title = this.firm_name;
                if(isWeiXin() && this.canPay){
                    this.isWXPay = true;
                }
            })
        },
        initMap(){
            let that = this;
            this.map = new AMap.Map('container', {
                zoom: this.zoom,
                resizeEnable: true
            })
            console.log(that.lng,that.lng,'that.lng')
            if(that.lng && that.lat){
                that.map.setCenter([that.lng, that.lat]);
                that.setMarker();
            }else{
                this.getLocation();
            }
        },
        getLocation(){
            this.$toast.loading({
                message: '加载中...',
                forbidClick: true, 
                duration: 0
            })
            let that = this;
            AMap.plugin('AMap.Geolocation', function() {
                let geolocation = new AMap.Geolocation({
                    enableHighAccuracy: true,//是否使用高精度定位，默认:true
                    timeout: 10000,
                    showButton: false,
                    maximunAge: 30000,
                    buttonPosition: 'RB',    //定位按钮停靠位置，默认：'LB'，左下角
                    buttonOffset: new AMap.Pixel(10, 450),//定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
                    extensions: 'all',
                    GeoLocationFirst: true
                })
                that.map.addControl(geolocation);
                geolocation.getCurrentPosition(function(status,result){
                    console.log(status, result)
                    that.$toast.clear();
                    if(status=='complete'){
                        that.$toast('定位成功');
                        that.lng = result.position.lng;
                        that.lat = result.position.lat;
                        let lnglat = [that.lng, that.lat]
                        let geocoder = new AMap.Geocoder()
                        geocoder.getAddress(lnglat, function(status2, result2) {
                            // console.log(status2, result2, 'status2, result2')
                            if (status2 === 'complete' && result2.info === 'OK') {
                                // result为对应的地理位置详细信息
                                let regeocode = result2.regeocode;
                                that.address = regeocode.formattedAddress;
                                that.province = regeocode.addressComponent.province;
                                that.city = regeocode.addressComponent.city;
                                that.district = regeocode.addressComponent.district;
                                let location = {
                                    address: that.address,
                                    city: that.city,
                                    district: that.district,
                                    lat: that.lat,
                                    lng: that.lng,
                                    province: that.province,
                                    type: 1
                                }
                                that.$store.commit('SET_LOCATION', location);
                                that.getConfig();
                                that.setMarker();
                            }
                        })
                    }else{
                        // alert(JSON.stringify(result))
                        if(result.info == 'NOT_SUPPORTED'){
                            that.$toast('当前浏览器不支持定位功能！');
                        }else{
                            if(result.message == 'Get geolocation time out'){
                                that.$toast('定位超时！');
                                return
                            }
                            if(result.message == 'Geolocation permission denied.'){
                                that.$toast('未授权获取当前位置');
                                return
                            }else{
                                that.getLngLatLocation();
                            }
                        }
                    }
                });
            })
        },
        getLngLatLocation() {
            let that = this;
            AMap.plugin("AMap.CitySearch", function () {
                var citySearch = new AMap.CitySearch();
                citySearch.getLocalCity(function (status, result) {
                    if (status === "complete" && result.info === "OK") {
                        // 查询成功，result即为当前所在城市信息
                        console.log(result);
                        let position = result.bounds.getCenter();
                        that.lng = position.lng;
                        that.lat = position.lat;
                        that.address = result.province + result.city;
                        that.province = result.province;
                        that.city = result.city;
                        that.district = '';
                        let location = {
                            address: that.address,
                            city: that.city,
                            district: that.district,
                            lat: that.lat,
                            lng: that.lng,
                            province: that.province,
                            type: 1
                        }
                        that.$store.commit('SET_LOCATION', location);
                        that.getConfig();
                        that.setMarker();
                    }else{
                        that.$toast('定位失败');
                    }
                });
            });
        },
        setMarker(){
            let that = this;
            that.map.remove(that.marker);
            let marker;
            let marker_s = new AMap.Marker({
                icon: new AMap.Icon({
                    size: new window.AMap.Size(26, 38),
                    image: that.marker_startIcon,
                    imageOffset: new window.AMap.Pixel(0, 0),
                    imageSize: new window.AMap.Size(26, 38)
                }),
                position: [that.lng, that.lat],
                offset: new AMap.Pixel(-13, -38),
            })
            marker = [marker_s]
            if(this.$jyTypeData(this.form.type, "address") == 2){
                if(this.e_lat && this.e_lng){
                    let marker_e = new AMap.Marker({
                        icon: new AMap.Icon({
                            size: new window.AMap.Size(26, 38),
                            image: that.marker_end,
                            imageOffset: new window.AMap.Pixel(0, 0),
                            imageSize: new window.AMap.Size(26, 38)
                        }),
                        position: [that.e_lng, that.e_lat],
                        offset: new AMap.Pixel(-13, -38),
                    })
                    marker = [marker_s, marker_e]
                }
            }
            this.marker = marker;
            that.map.add(this.marker);
            that.map.setFitView();
        },
        getConfig(){
            let data = {
                customer_id: this.customer_id,
                province: this.province,
                city: this.city,
                area: this.district
            }
            getPriceConfig(data).then((res)=>{
                let jyList = res.data.data;
                let type = this.form.type;
                let validType = true;
                if(jyList.length != 0){
                    jyList.forEach((item)=>{
                        if(item.type == type){
                            validType = false;
                        }
                    })
                    if(validType){
                        this.form.type = jyList[0].type;
                        this.priceInfo = jyList[0];
                    }
                    this.jyTypeData = jyList;
                }else{
                    this.jyTypeData = [];
                    this.form.type = '';
                }
                console.log(this.jyTypeData,'价格配置')
            })
        },
        // 选择救援类型
        handType(e){
            if(this.form.type != e.type){
                this.form.type = e.type;
                this.priceInfo = e;
                this.demand = [];
                if(this.$jyTypeData(e.type, 'address') == 2){
                    if(this.lng && this.lat){
                        this.map.remove(this.marker);
                        this.setMarker();
                    }
                }else{
                    if(this.marker[1]){
                        this.marker[1].setMap(null);
                    }
                }
            }
        },
        // 选择地点
        openLocation(index){
            let lng = index == 2 && this.e_lng ? this.e_lng : this.lng;
            let lat = index == 2 && this.e_lat ? this.e_lat : this.lat;
            let address = index == 2 && this.e_address ? this.e_address : this.address;
            let city = index == 2 && this.e_city ? this.e_address : this.city;
            this.$router.push({
                path: '/location',
                query: {
                    lng,
                    lat,
                    address,
                    city,
                    type: index
                }
            })
        },
        // 下一步
        nextStep(){
            if(this.jyTypeData.length == 0){
                this.$toast('暂未开通服务');
                return
            }
            if(!this.lng || !this.lat){
                this.$toast('请选择救援点');
                return
            }
            if(!this.form.contact){
                if(this.form.type == 1 || this.form.type == 11){
                    this.$toast('请输入装车人');
                }else{
                    this.$toast('请输入联系人');
                }
                return
            }
            if(!this.form.phone){
                this.$toast('请输入联系电话');
                return
            }else{
                let phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/;
                if(!phonereg.test(this.form.phone)){
                    this.$toast('手机号错误！');
                    return
                }
            }
            if(this.$jyTypeData(this.form.type, "address") == 2){
                if(!this.e_lng || !this.e_lat){
                    this.$toast('请选择目的地');
                    return
                }
            }
            if(this.canPay){
                this.computedMoney();
            }
            this.filterTime();
            this.step = 2;
        },
        // 上一步
        proStep(){
            this.step = 1;
        },
        // 车牌号输入取消
        exit(){
            this.keyState = false;
        },
        // 车牌号确认
        confirm(e){
            console.log(e, '车牌号确认')
            this.form.car_no = e;
            this.exit();
        },
        filterTime(){
            this.timePicker = [];
            for(let i=0; i<7; i++){
                let date = this.getDate(i);
                this.timePicker.push(date)
            }
        },
        getDate(n){
            let ss = 24 * 60 *60 * 1000;            // 一天毫秒数
            let timestamp = new Date().getTime(); //获取当前时间戳
            let date = new Date(ss * n + timestamp);
            let wk = date.getDay();
            let y = date.getFullYear();
            let m = this.fnTime(date.getMonth() + 1);
            let d = this.fnTime(date.getDate());
            let weeks = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
            let week;
            if(n == 0){
                week = '今天';
            }else if(n == 1){
                week = '明天';
            }else if(n == 2){
                week = '后天';
            }else{
                week = weeks[wk];
            }
            this.intervalTimeArr = [];
            this.intervalTime(n);
            let dateText = y + '-' + m + '-' + d + ' ' + week;
            let dateObj = {
                text: dateText,
                children: this.intervalTimeArr
            }
            return dateObj
        },
        fnTime(e){
            return e < 10 ? '0' + e : e
        },
        // 计算间隔时间间隔
        intervalTime(n, e){
            // 获取当前时间戳
            let startTiem;
            let endTime = e ? e : 0;
            let intervalTime;
            if(n == 0){
                startTiem = Date.parse(new Date()) + 1000*60*60*2;
                endTime = endTime ? endTime : new Date(new Date(new Date().toLocaleDateString()).getTime() + 24*60*60*1000);
                intervalTime = endTime - 1000*60*60;       //当天零点
            }else{
                startTiem = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24*60*60*1000);
                endTime = endTime ? endTime : new Date(new Date(new Date().toLocaleDateString()).getTime() + 24*60*60*1000*2);
                intervalTime = endTime;        //当天零点
            }
            if(intervalTime > startTiem){
                let date = n == 0 ? new Date(intervalTime) : new Date(intervalTime - 1000*60*60);
                endTime = Date.parse(date);
                //时  
                let h = this.fnTime(date.getHours());  
                //分  
                let m = this.fnTime(date.getMinutes());  
                let timeStr = {
                    text: h + ':' + m
                };
                this.intervalTimeArr.push(timeStr);
                this.intervalTime(n, endTime);
            }else{
                this.intervalTimeArr.reverse();
                if(n == 0){
                    let timeStr = {
                        text: '尽快到达'
                    };
                    this.intervalTimeArr.unshift(timeStr);
                }
            }
        },
        // 确认选择时间
        confirmTime(e){
            // console.log(e,'确认选择时间');
            if(e[1] == '尽快到达'){
                this.form.is_subscribe = 1;
                this.form.appoint_time = e[1];
            }else{
                this.form.is_subscribe = 2;
                let date = e[0].split(' ');
                this.form.appoint_time = date[0] + ' ' + e[1] + ':00';
            }
            this.showPicker = false;
        },
        // 确认选择汽车类型
        confirmCartype(e){
            this.form.car_type = e.value;
            this.cartypePicker = false;
        },
        // 确认选择吊车吨位
        confirmCraneTonnage(e){
            this.form.tonne_m = e.value;
            this.craneTonnagePicker = false;
        },
        // 删除图片
        clearImg(index){
            this.uploadImgs.splice(index, 1);
        },
        // 取消
        cancelUpload(){
            this.photoPopup = false;
            this.uploadImgs = this.form.photo.length !== 0 ? JSON.parse(JSON.stringify(this.form.photo)) : [];
        },
        // 确定
        affirmUpload(){
            this.form.photo = this.uploadImgs.length !== 0 ? JSON.parse(JSON.stringify(this.uploadImgs)) : [];
            this.form.attachment = [];
            this.form.photo.forEach((i)=>{
                let obj = {
                    type: 1,
                    url: i
                }
                this.form.attachment.push(obj);
            })
            this.photoPopup = false;
        },
        // 计算价格
        computedMoney(){
            let data = {
                customer_id: this.customer_id,
                type: this.form.type,
                province: this.province,
                city: this.city,
                area: this.district,
                use_wheels: 0,
                science: 0,
                big_vehicleL: 0,
                one_two: 0,
                full: 0,
                theodolitic: this.lng + ',' + this.lat,
                transit: this.$jyTypeData(this.form.type, "address") == 2 ? this.e_lng + ',' + this.e_lat : ''
            }
            if((this.form.type == 1 || this.form.type == 11) && this.demand.length != 0){
                this.demand.forEach((i)=>{
                    data[i] = 1;
                })
            }
            computePrice(data).then((res)=>{
                console.log(res,'计算价格')
                if(res.data.code == 1){
                    this.moneyInfo = res.data.data;
                }else{
                    this.$toast(res.data.info);
                }
            })
        },
        // 下单效验
        validSubmit(){
            console.log(this.demand,'附加项');
            if(this.disabledSubmit){
                return
            };
            if(!this.form.car_no){
                this.$toast('车牌号不能为空！');
                return
            };
            if(this.form.is_incident_car){
                if(!this.form.checker_name || !this.form.checker_phone){
                    this.$toast('请输入查勘员信息！');
                    return
                }else if(this.customer_id == this.default_customer_id){
                    // 验证码效验
                    let data = {
                        type: 'send',
                        phone: this.form.checker_phone
                    };
                    sms_post(data).then((res)=>{
                        console.log(res, '发送验证码')
                        if(res.data.Code == "OK" && res.data.data){
                            this.mobile_valid_dialog = true;
                        }
                    })
                    return
                }
            }else{
                this.form.checker_name = '';
                this.form.checker_phone = '';
            };
            this.submitOrder();
        },
        // 效验查勘员验证码
        inputFocus(){
            this.$refs.input.focus();
        },
        changeInput(){
            setTimeout(()=>{
                if(this.validValue.length == this.valid_length){
                    console.log(this.validValue, '接口效验')
                    let data = {
                        type: 'valid',
                        phone: this.form.checker_phone,
                        code: this.validValue
                    };
                    sms_post(data).then((res)=>{
                        console.log(res, '效验')
                        if(res.data.Code == "OK" && res.data.data){
                            this.submitOrder();
                            this.closeDialog();
                        }else{
                            this.checkPhoneText = res.data.msg || '验证失败';
                            this.checkPhoneStatus = false;
                        }
                    })
                }
                if(this.validValue.length < this.valid_length){
                    this.checkPhoneText = '';
                    this.checkPhoneStatus = true;
                }
            }, 0)
        },
         // 关闭效验框
        closeDialog(){
            this.mobile_valid_dialog = false;
            this.validValue = '';
        },
        // 确认下单
        submitOrder(){
            this.disabledSubmit = true;
            if(this.demand.length !== 0){
                this.demand.forEach((i)=>{
                    this.form[i] = 1;
                })
            }
            this.form.theodolitic = this.lng + ',' + this.lat;  //救援地坐标
            this.form.a_province = this.province;  //救援地省
            this.form.a_city = this.city;  //救援地市
            this.form.a_area = this.district;  //救援地区
            this.form.address = this.address;   //详细地址
            this.form.load_contact = this.form.contact;
            this.form.load_mobile = this.form.phone;
            if(this.$jyTypeData(this.form.type, "address") == 2){
                // 轿运、 拖车
                this.form.transit = this.e_lng + ',' + this.e_lat;  //目的地坐标
                this.form.d_province = this.e_province;  //目的地省
                this.form.d_city = this.e_city;  //目的地市
                this.form.d_area = this.e_district;  //目的地区
                this.form.destination = this.e_address;  //目的地详细地址
                if(this.form.type == 1){
                    this.form.one_two = 0;  //一拖二
                    this.form.full = 0;  //全落地
                    this.form.big_vehicle = 0;
                }
                if(this.form.type == 11){
                    this.form.science = 0;  //车在地库
                    this.form.use_wheels = 0;  //辅轮
                }
            }else{
                this.form.transit = '';  //目的地坐标    
                this.form.d_province = '';  //目的地省
                this.form.d_city = '';  //目的地市
                this.form.d_area = '';  //目的地区
                this.form.destination = '';  //目的地详细地址
            }
            let data = JSON.parse(JSON.stringify(this.form));
            if(data.type != 10){
                // 非吊车
                data.tonne_m = '';
            }
            if(this.canPay){
                data.money = this.moneyInfo.total_money;
            }
            data.is_incident_car = this.form.is_incident_car ? 1 : 0;
            if(data.is_subscribe == 2){
                data.appoint_time = Date.parse(new Date(data.appoint_time)) / 1000;
            }
            console.log(data,'下单参数')
            createOrder(data).then((res)=>{
                console.log(res,'下单');
                this.disabledSubmit = false;
                if(res.data.code == 1){
                    // 下单成功
                    this.order_id = res.data.data.id;
                    if(this.canPay){
                        // 打开选择支付
                        this.onClose();
                    }else{
                        this.orderSuccess = true;
                    }
                }else{
                    this.$toast(res.data.info);
                }
            }).catch(()=>{
                this.disabledSubmit = false;
            })
        },
        // 继续下单
        continueCreate(){
            this.resetData();
        },
        // 查看订单
        seeOrder(index){
            this.orderSuccess = false;
            this.$router.push({
                path: '/order',
                query: {
                    name: this.customer_name,
                    firm_name: this.firm_name,
                    customer_id: this.customer_id,
                    firm_id: this.firm_id,
                    mobile: index == 1 ? this.form.phone : ''
                }
            })
        },
        openPhotoPopup(){
            this.photoPopup = true;
        },
        // oss参数获取
        getOSSParam() {
            ossParams().then((res)=>{
                console.log("阿里云返回签名等信息", res.data)
                this.ossInfo = res.data.data;
            })
        },
        // 上传前
        beforeRead(file) {
            if(this.uploadImgs.length > 4){
                this.$toast('现场照片不能超出5张');
                return false
            }
            return true;
        },
        // 上传现场照片
        afterRead(file){
            let that = this;
            let uploadUrl = that.ossInfo.host;
            let fileName = file.file.name.substring(file.file.name.lastIndexOf(".") + 1, file.file.name.length);
            let keyInfo = that.ossInfo.dir + '/' + that.randomString(32) + '.' + fileName;
            let formData = new FormData();
            formData.append('Filename', 'upload');
            formData.append('name', 'upload');
            formData.append('key', keyInfo);
            formData.append('policy', that.ossInfo.policy);
            formData.append('OSSAccessKeyId', that.ossInfo.accessid);
            formData.append('success_action_status', 200);
            formData.append('signature', that.ossInfo.signature);
            formData.append('file', file.file);
            uploadFileOss(uploadUrl, formData).then((res)=>{
                // console.log(uploadUrl + '/' + keyInfo,'阿里云图片');
                this.uploadImgs.push(uploadUrl + '/' + keyInfo);
            }).catch((e)=>{
                this.$toast('上传失败')
            })
        },
        randomString(len) {
            len = len || 32;
            let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'; /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
            let maxPos = chars.length;
            let pwd = '';
            for (let i = 0; i < len; i++) {
                pwd += chars.charAt(Math.floor(Math.random() * maxPos));
            }
            return pwd;
        },
        // 查看服务照片
        preImg(index){
            this.preIndex = index;
            this.preImgShow = true;
        },
        preChange(index) {
            this.preIndex = index;
        },
        // 数据初始化
        resetData(){
            let type = this.form.type
            // this.priceInfo = this.jyTypeData[0];
            this.step = 1;            //下单步骤
            this.demand = [];             //额外需求
            this.uploadImgs = [];         //照片
            this.form = {
                type,               //救援类型
                contact: '',               //装车人
                phone: '',              //手机号
                car_no: '',             //车牌号码
                car_brand: '',          //车辆品牌
                car_model: '',          //品牌型号
                car_type: '1',            //车型  1  轿车  2  货车
                is_incident_car: false,        //是否事故
                photo: [],                  //现场图片
                science: '',                //救援环境 1 车在地库 2 车在路面（手刹空档）正常 3 车在路面（手刹空档）异常
                big_vehicle: 0,             //5吨板 0 否 1 是
                one_two: 0,                 //一拖二 0 否 1 是
                full: 0,                    //全落地 0 否 1 是
                use_wheels: 0,              //辅轮
                load_contact: '',           //装车联系人
                load_mobile: '',           //装车联系人电话
                unload_contact: '',           //卸车联系人
                unload_mobile: '',           //卸车联系人电话
                is_subscribe: '1',          //1 及时救援 2 预约救援
                appoint_time: '尽快到达',           //预约时间戳 is_subscribe 2 时使用
                remark: '',                  //订单备注
                a_province: '',             //救援地省
                a_city: '',             //救援地市
                a_area: '',             //救援地区
                address: '',             //详细地址
                theodolitic: '',            //救援地坐标
                d_province: '',             //目的地省
                d_city: '',             //目的地市
                d_area: '',             //目的地区
                destination: '',             //目的地详细地址
                transit: '',            //目的地坐标
                vin: '',            //车架号
                policy_no: '',           //保单号
                accident_no: '',           //事故编号
                checker_name: '',          //查勘员姓名
                checker_phone: '',          //查勘员电话
                customer_id: this.customer_id,       //客户id
                tonne_m: 8
            }
        },
        // 关闭支付
        onClose(){
            setTimeout(() => {
                this.resetData();
            }, 1000);
            this.$router.push({
                path: '/orderDetail',
                query: {
                    id: this.order_id
                }
            })
        },
        // 输入识别
        distinguish(){
            let info = regularValue(this.distinguish_text);
            this.distinguish_info = info;
            console.log(this.distinguish_info, 'this.distinguish_info')
        },
        // 关闭识别
        close_distinguish(){
            this.distinguish_show = false;
            setTimeout(()=>{
                this.distinguish_text = '';
                this.distinguish_info = '';
            }, 500)
        },
        // 确认填充识别信息
        affirm_distinguish(){
            this.form.contact = this.distinguish_info.name;               //装车人
            this.form.phone = this.distinguish_info.phone;               //联系电话
            this.form.load_contact = this.distinguish_info.load_contact;               //装车人
            this.form.load_mobile = this.distinguish_info.load_mobile;               //联系电话
            this.form.car_no = this.distinguish_info.carNo;               //车牌号码
            this.form.car_model = this.distinguish_info.car_model;               //品牌型号
            this.form.vin = this.distinguish_info.vin;               //车架号
            this.form.policy_no = this.distinguish_info.no;               //保单号
            this.form.is_incident_car = this.distinguish_info.accident || this.customer_id == this.default_customer_id ? true : false;               //是否事故
            this.form.accident_no = this.customer_id == this.default_customer_id ? this.distinguish_info.no : this.distinguish_info.accident;               //事故编号
            this.close_distinguish();
        },
        // 选择查勘员
        toSurveyor(){
            this.$router.push({
                 path: '/surveyor'
            })
        }
    }
}
</script>

<style lang='scss' scoped>
    .view{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        .amap{
            height: 100%;
        }
        .hearder{
            z-index: 99;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            height: 47px;
            background: rgba(255, 255, 255, 0.83);
            display: flex;
            padding: 0 15px;
            box-sizing: border-box;
            align-items: center;
            justify-content: space-between;
            .firmName{
                display: flex;
                align-items: center;
                font-size: 16px;
                line-height: 22px;
                color: #1F2129;
                .firmIcon{
                    width: 16px;
                    height: 15px;
                }
                .customerName{
                    margin-left: 5px;
                }
            }
            .openOrder{
                width: 64px;
                height: 28px;
                background: #FFFFFF;
                border: 1px solid var(--theme-color);
                opacity: 1;
                border-radius: 4px;
                font-size: 14px;
                color: var(--theme-text-color);
                text-align: center;
                line-height: 28px;
            }
        }
        .content{
            z-index: 99;
            background: #FFFFFF;
            border-radius: 16px 16px 0px 0px;
            position: fixed;
            left: 10px;
            right: 10px;
            bottom: 18px;
            .stepOne{
                width: 100%;
                .distinguish_btn{
                    position: absolute;
                    top: -40px;
                    left: 15px;
                    display: inline-block;
                    padding: 0 10px;
                    height: 24px;
                    font-size: 14px;
                    text-align: center;
                    line-height: 24px;
                    color: #8A8A8A;
                    background: var(--theme-color);
                    color: #FFFFFF;
                    border-radius: 5px;
                }
                .info{
                    padding: 18px 15px 10px;
                    box-sizing: border-box;
                    .tabbar{
                        width: 325px;
                        overflow-x: scroll;
                        white-space: nowrap;
                        // display: flex;
                        // justify-content: space-between;
                        // align-items: center;
                        margin-bottom: 10px;
                        &::-webkit-scrollbar {
                            display: none;
                        }
                        .tabbar_li{
                            display: inline-block;
                            padding: 0 8px;
                            box-sizing: border-box;
                            min-width: 48px;
                            height: 24px;
                            font-size: 14px;
                            text-align: center;
                            line-height: 24px;
                            color: #8A8A8A;
                            margin-right: 20px;
                        }
                        .activeTabbar{
                            background: var(--theme-color);
                            color: #FFFFFF;
                            border-radius: 5px;
                        }
                        .noServe{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 14px;
                            font-family: PingFang SC;
                            font-weight: 400;
                            line-height: 20px;
                            color: #FF4B13;
                            img{
                                width: 16px;
                                height: 16px;
                                margin-right: 5px;
                            }
                        }
                    }
                    .info_li{
                        height: 47px;
                        border-bottom: 1px solid #ECECEC;
                        display: flex;
                        align-items: center;
                    }
                    .address{
                        img{
                            width: 10px;
                            height: 12px;
                        }
                        .label{
                            font-size: 14px;
                            line-height: 20px;
                            color: #999999;
                            margin: 0 10px 0 5px;
                        }
                        .name{
                            font-size: 14px;
                            line-height: 20px;
                            color: #141414;
                            max-width: 250px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                    .userInfo{
                        .username{
                            display: flex;
                            align-items: center;
                            img{
                                width: 16px;
                                height: 16px;
                            }
                        }
                    }
                }
                .footerBtn{
                    width: 100%;
                    height: 46px;
                    background: var(--theme-color);
                    font-size: 16px;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 46px;
                }
            }
            .stepTwo{
                width: 100%;
                .form{
                    padding: 10px 15px;
                    box-sizing: border-box;
                    font-family: PingFang SC;
                    font-weight: 400;
                    font-size: 14px;
                    .li{
                        display: flex;
                        align-items: center;
                        height: 41px;
                        border-bottom: 1px solid #ECECEC;
                        .label{
                            min-width: 57px;
                            height: 20px;
                            line-height: 20px;
                            color: #8A8A8A;
                            margin-right: 17px;
                        }
                        .moneyLabel{
                            display: flex;
                            align-items: center;
                            span{
                                margin-right: 8px;
                            }
                        }
                        .value{
                            height: 40px;
                            flex: 1;
                            color: #141414;
                            display: flex;
                            align-items: center;
                            .van-cell{
                                height: 40px;
                                line-height: 40px;
                                box-sizing: border-box;
                                display: flex;
                                align-items: center;
                                padding: 0;
                            }
                            .right_icon{
                                width: 100%;
                                height: 100%;
                                display: flex;
                                justify-content: flex-end;
                                align-items: center;
                                span{
                                    margin-right: 16px;
                                }
                            }
                            .accidentInput{
                                width: 188px;
                                height: 28px;
                                background: #FFFFFF;
                                border: 1px solid #C7C7C7;
                                border-radius: 4px;
                                padding: 0 10px;
                                box-sizing: border-box;
                                margin-left: 16px;
                            }
                            .demandView{
                                width: 100%;
                                height: 100%;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                            }
                        }
                        .accident_user{
                            .van-cell{
                                padding: 0 10px 0 0;
                                box-sizing: border-box;
                            }
                        }
                        .money{
                            z-index: 99;
                            width: 100%;
                            color: #FF4B13;
                            justify-content: flex-end;
                            font-size: 16px;
                            font-weight: bold;
                        }
                    }
                }
                .footerBtn{
                    display: flex;
                    height: 46px;
                    font-size: 16px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    div{
                        flex: 1;
                        text-align: center;
                        line-height: 46px;
                    }
                    .preStep{
                        background: #ECECEC;
                        color: #141414;
                    }
                    .submit{
                        background: var(--theme-color);
                        color: #fff;
                    }
                }
            }
        }
        .photoPopup{
            height: 420px;
            padding: 20px 15px 30px;
            box-sizing: border-box;
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #1F2129;
            .title{
                text-align: center;
                padding-bottom: 20px;
            }
            .photoInfo{
                .tips{
                    display: flex;
                    img{
                        width: 15px;
                        height: 15px;
                        margin-right: 7px;
                        margin-top: 4px;
                    }
                    p{
                        font-size: 11px;
                        font-family: PingFangSC-Medium;
                        line-height: 21px;
                        color: #FF4B13;
                    }
                }
                .list{
                    height: 248px;
                    padding-top: 16px;
                    box-sizing: border-box;
                    overflow-y: scroll;
                    .imgBox{
                        display: flex;
                        flex-wrap: wrap;
                    }
                    .li{
                        display: inline-block;
                        position: relative;
                        margin-right: 15px;
                        margin-bottom: 15px;
                        img{
                            width: 105px;
                            height: 79px;
                        }
                        .clearImg{
                            position: absolute;
                            top: 3px;
                            right: 3px;
                        }
                    }
                    .uploadBtn{
                        width: 105px;
                        height: 79px;
                        margin-bottom: 15px;
                        /deep/ .van-uploader__upload{
                            width: 105px;
                            height: 79px;
                            margin: 0;
                        }
                    }
                }
                .footerBtn{
                    display: flex;
                    justify-content: space-between;
                    div{
                        height: 46px;
                        opacity: 1;
                        border-radius: 6px;
                        text-align: center;
                        line-height: 46px;
                        font-size: 16px;
                        font-family: PingFangSC-Medium;
                    }
                    .cancelBtn{
                        width: 135px;
                        background: #ECECEC;
                        color: #141414;
                    }
                    .affirmBtn{
                        width: 180px;
                        background: var(--theme-color);
                        color: #FFFFFF;
                    }
                }
            }
        }
        .van-dialog{
            border-radius: 8px;
        }
        .passwordInput{
            width: 271px;
            border: 1px solid #EFEFEF;
            opacity: 1;
            border-radius: 4px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            margin: 22px auto 24px;
        }
        .orderSuccess{
            padding: 27px 0 29px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            img{
                width: 38px;
                height: 38px;
                margin-bottom: 10px;
            }
            span{
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 400;
                line-height: 22px;
                color: #191818;
            }
        }
        .content{
            font-family: PingFang SC;
            .moneyInfo{
                padding: 15px 15px 0;
                box-sizing: border-box;
            }
            .title{
                font-size: 16px;
                font-weight: bold;
                line-height: 20px;
                color: #141414;
                margin-bottom: 10px;
            }
            .tips{
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
                color: #666666;
                margin-bottom: 20px;
                letter-spacing: 1px;
                .li{
                    margin-bottom: 8px;
                }
            }
            .btn{
                width: 100%;
                height: 46px;
                background: var(--theme-color);
                text-align: center;
                line-height: 46px;
                color: #fff;
                font-size: 16px;
                font-weight: 400;
            }
        }
        .payInfo{
            min-height: 200px;
            padding: 23px 15px 30px;
            box-sizing: border-box;
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #1F2129;
            .title{
                text-align: center;
                margin-bottom: 24px;
                .text{
                    font-size: 14px;
                    line-height: 20px;
                    color: #647086;
                    margin-right: 8px;
                }
                .money{
                    font-size: 24px;
                    font-weight: bold;
                    color: #FF4B13;
                    line-height: 32px;
                }
            }
            .tips{
                font-size: 14px;
                font-weight: bold;
                line-height: 20px;
                color: #141414;
                margin-bottom: 15px;
            }
            .payList{
                .payLi{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .payName{
                        display: flex;
                        align-items: center;
                        color: #141414;
                        font-size: 14px;
                        img{
                            width: 22px;
                            height: 22px;
                            margin-right: 12px;
                        }
                    }
                }
            }
            .payBtn{
                margin: 0 auto;
                margin-top: 36px;
                width: 325px;
                height: 46px;
                background: var(--theme-color);
                border-radius: 23px;
                text-align: center;
                line-height: 46px;
                font-size: 16px;
                color: #FFFFFF;
            }
        }
        .wx_yd{
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            z-index: 9999;
        }
        .overlay_view{
            display: flex;
            justify-content: center;
            align-items: center;
            .distinguish_box{
                width: 340px;
                background: #fff;
                position: relative;
                .cross_icon{
                    position: absolute;
                    right: 16px;
                    top: 20px;
                }
                .distinguish_title{
                    margin: 20px 0;
                    text-align: center;
                }
                .distinguish_info{
                    padding: 16px;
                    font-size: 12px;
                    .distinguish_li{
                        margin-bottom: 6px;
                    }
                }
                .distinguish_affirm{
                    width: 100%;
                    height: 46px;
                    background: var(--theme-color);
                    text-align: center;
                    line-height: 46px;
                    color: #fff;
                    font-size: 16px;
                    font-weight: 400;
                }
            }
        }
        .validBox{
            font-size: 14px;
            .dialog_close_icon{
                position: absolute;
                top: 10px;
                right: 10px;
            }
            .phoneInput_title{
                padding: 20px 0;
		        line-height: 22px;
                text-align: center;
            }
            .phoneError{
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                .error_text{
                    text-align: center;
                    font-size: 12px;
                    color: #FF451E;
                }
            }
            .u-pop-message{
                padding: 0 18px;
                display: flex;
                justify-content: space-between;
                .valid_li{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 64px;
                    height: 64px;
                    border: 1px solid #EFEFEF;
                    border-radius: 2px;
                }
                .activeFocus{
                    border: 1px solid var(--theme-color);
                    z-index: 9;
                }
            }
        }
        .valid_input{
            position: fixed;
            bottom: 2000px;
            right: 0;
            left: 0;
        }
    }
</style>
