<template>
    <div class="view">
        <img v-if="isWX" src="@/assets/rescueImgs/wx_yd.png" alt="" class="wx_yd" />
        <template v-else-if="source == 'xj'">
            <div class="downloadView" v-if="type == 0">
                <img src="@/assets/appVersion/xjDriver.png" alt="" class="downloadBg" />
                <img src="@/assets/appVersion/xjDLogo.png" alt="" class="dLogo" />
                <div class="dBtn" @click="downLoad(xjDInfo)">下载师傅端APP</div>
            </div>
            <div class="downloadView" v-if="type == 1">
                <img src="@/assets/appVersion/xjMerchant.png" alt="" class="downloadBg" />
                <img src="@/assets/appVersion/xjMLogo.png" alt="" class="MLogo" />
                <div class="mBtn" @click="downLoad(xjMInfo)">下载商家端APP</div>
            </div>
        </template>
        <template v-else-if="source == 'tp'">
            <div class="downloadView" v-if="type == 0">
                <img src="@/assets/appVersion/tpDriver.png" alt="" class="downloadBg" />
                <img src="@/assets/appVersion/tpLogo.png" alt="" class="dLogo" />
                <div class="dBtn" @click="downLoad(tpDInfo)">下载师傅端APP</div>
            </div>
            <div class="downloadView" v-if="type == 1">
                <img src="@/assets/appVersion/tpMerchant.png" alt="" class="downloadBg" />
                <img src="@/assets/appVersion/tpLogo.png" alt="" class="MLogo" />
                <div class="mBtn" @click="downLoad(tpMInfo)">下载商家端APP</div>
            </div>
        </template>
        <template v-else-if="source == 'cct'">
            <div class="downloadView" v-if="type == 0">
                <img src="@/assets/appVersion/cctDriver.png" alt="" class="downloadBg" />
                <img src="@/assets/appVersion/cctLogo.png" alt="" class="dLogo" />
                <div class="dBtn cctBtn" @click="downLoad(tpDInfo)">下载师傅端APP</div>
            </div>
            <div class="downloadView" v-if="type == 1">
                <img src="@/assets/appVersion/cctMerchant.png" alt="" class="downloadBg" />
                <img src="@/assets/appVersion/cctLogo.png" alt="" class="MLogo" />
                <div class="mBtn cctBtn" @click="downLoad(tpMInfo)">下载商家端APP</div>
            </div>
        </template>
    </div>
</template>
<script>
import { isWeiXin, getQueryString } from '@/utils/index';
import { appDownload } from '@/apis/unicloud/api';
export default {
    data(){
        return {
            isWX: false,
            source: '',
            type: '99',        //0：只显示技师端  1：只显示商家端
            xjDInfo: {
                appid: '__UNI__6C50391',
                name: '星际师傅端',
                logo: require('@/assets/logo/xj-logo.png'),
                tips: '用于星际师傅服务接单',
                source: 'xj',
                iosLink: '',
                type: 0
            },
            xjMInfo: {
                appid: '__UNI__B937FB8',
                name: '星际商家端',
                logo: require('@/assets/logo/xj-logo.png'),
                tips: '用于星际商家派单结算等',
                source: 'xj',
                iosLink: '',
                type: 1
            },
            tpDInfo: {
                appid: '__UNI__F86FA9D',
                name: '通平师傅端',
                logo: require('@/assets/logo/tp_logo.png'),
                tips: '用于通平师傅服务接单',
                source: 'tp',
                iosLink: '',
                type: 0
            },
            tpMInfo: {
                appid: '__UNI__9D7A833',
                name: '通平商家端',
                logo: require('@/assets/logo/tp_logo.png'),
                tips: '用于通平商家派单结算等',
                source: 'tp',
                iosLink: '',
                type: 1
            },
            cctDInfo: {
                appid: '__UNI__44AAEC0',
                name: '车车通师傅端',
                logo: require('@/assets/logo/cct_logo.png'),
                tips: '用于车车通师傅服务接单',
                source: 'cct',
                iosLink: '',
                type: 0
            },
            cctMInfo: {
                appid: '__UNI__FFCD6E2',
                name: '车车通商家端',
                logo: require('@/assets/logo/cct_logo.png'),
                tips: '用于车车通商家派单结算等',
                source: 'cct',
                iosLink: 'https://apps.apple.com/cn/app/%E8%BD%A6%E8%BD%A6%E9%80%9A%E5%95%86%E5%AE%B6%E7%AB%AF/id6615070085',
                type: 1
            }
        }
    },
    created(){
        let type = getQueryString('type');
        let source = getQueryString('source');
        if(source){
            this.source = source;
        }
        if(type){
            this.type = type;
        }
        if(isWeiXin()){
            this.isWX = true;
        }
    },
    methods: {
        downLoad(e){
            let ran = navigator.userAgent
            let isAndroid = ran.indexOf('Android') > -1 || ran.indexOf('Linux') > -1
            let isIOS = !!ran.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            let appid = e.appid;
            if(isAndroid){
                appDownload({appid}).then((res)=>{
                    if(res.data.Code != 0 && res.data.data){
                        let url = res.data.data;
                        window.location.href = url;
                    }else{
                        this.$notify({
                            type: 'danger',
                            message: res.data.msg
                        });
                    }
                })
            }
            if(isIOS){
                if(e.iosLink){
                    window.location.href = e.iosLink;
                }else{
                    this.$notify({
                        type: 'danger',
                        message: '暂不支持！'
                    });
                }
            }
        },
    }
}
</script>

<style lang='scss' scoped>
    .list{
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #F8F8F8;
        padding: 12px;
        box-sizing: border-box;
    }
    .li{
        display: flex;
        justify-content: space-between;
        padding: 12px;
        background: #fff;
        border-radius: 10px;
        margin-bottom: 12px;
        .logo{
            width: 70px;
            height: 70px;
            margin-right: 12px;
        }
        .right{
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .info{
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                .name{
                    font-size: 16px;
                    color: #333;
                    font-weight: bold;
                }
                .tips{
                    color: #999;
                    font-size: 13px;
                }
            }
            .btn{
                background: var(--theme-color);
                width: 60px;
                height: 32px;
                color: #fff;
                font-size: 14px;
                border-radius: 100px;
                font-weight: bold;
                text-align: center;
                line-height: 32px;
            }
        }
    }
    .wx_yd{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 9999;
    }
    .downloadView{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        position: relative;
        .downloadBg{
            width: 100%;
            height: 100%;
        }
        .dLogo{
            width: 100px;
            height: 100px;
            position: absolute;
            bottom: 318px;
        }
        .MLogo{
            width: 100px;
            height: 100px;
            position: absolute;
            top: 51px;
            left: 61px;
        }
        .dBtn, .mBtn{
            width: 289px;
            height: 44px;
            background: var(--theme-color);
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            font-weight: bold;
            font-size: 15px;
            color: var(--theme-btn);
        }
        .dBtn{
            bottom: 179px;
        }
        .mBtn{
            bottom: 128px;
        }
        .cctBtn{
            background: #76CDF5;
        }
    }
</style>
